@import './src/styles/variables-styles.scss';
// For password reset page
.auth-reset-page {
  height: 100vh;

  .auth-panel {
    margin: 50px 0;
    .header-logo {
      .app-logo {
        svg {
          width: 180px;
          height: 80px;
        }
      }
    }
  }

  .col-auth-panel {
    max-width: 600px;
  }

  .success-msg {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 70px;
  }
}

// Other styling
.auth-panel {
  max-height: 100vh;

  background-color: $color-light-1;
  border-radius: 5px;
  position: relative;
  font-family: $font-primary;

  .auth-panel-header {
    .header {
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-dark-1;
    }
  }

  .close-icon-container {
    position: absolute;
    right: 15px;
    padding: 0;
    width: fit-content;
    cursor: pointer;
    z-index: 100;

    .icon-close {
      svg {
        width: 18px;
        height: 18px;

        path {
          fill: $color-dark-1;
        }
      }
    }
    @media (min-width: $width-md) {
      top: 15px;
    }
    @media (max-width: $max-width-md) {
      top: 28px;
    }
  }

  .btn-facebook {
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-light-1;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: $color-facebook-2;
    width: 100%;
    position: relative;

    .btn-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        margin-top: -3px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .thin-copy {
    margin: 30px 0;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-dark-1;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;

    &.lines::after,
    &.lines::before {
      content: '';
      display: inline-block;
      width: 50%;
      margin: 0 0.5em 0 -55%;
      vertical-align: middle;
      border-bottom: 1px solid $color-dark-1;
    }

    &.lines::after {
      margin: 0 -55% 0 0.5em;
    }
  }

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .spinner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .auth-form {
    &.loading {
      visibility: hidden;
    }

    .register-options-container {
      display: flex;
      margin-bottom: 25px;
      justify-content: center;

      .nav-item {
        position: relative;
        cursor: pointer;
        width: 150px;
        font-size: 14px;
        text-decoration: none;
        font-weight: $font-weight-semi-bold;
        padding: 10px 0;
        border-bottom: 2px solid transparent;
        text-align: center;

        &.active {
          border-bottom-color: $appic-color-primary;
        }
      }
    }

    .form-field-container {
      margin-bottom: 5px;
      position: relative;

      .form-field-label {
        text-align: left;
        font-size: 16px;
        font-weight: $font-weight-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-dark-1;
        margin-bottom: 8px;
      }

      .form-field-icon {
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;

          path,
          circle {
            fill: $color-dark-2;
          }

          line,
          polyline {
            stroke: $color-dark-2;
          }
        }
      }

      .forgot-link {
        text-decoration: underline;
        color: $appic-color-primary;
        cursor: pointer;
      }

      .caps-tooltip {
        position: absolute;
        top: -4px;
        right: 15px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: $font-weight-semi-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        background-color: $appic-color-primary;
        padding: 4px 10px;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 4px);
          bottom: -8px;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid $appic-color-primary;
          clear: both;
        }
      }

      .form-field {
        position: relative;
        width: 100%;
        font-size: 16px;
        font-family: $font-primary !important;
        font-size: 16px;
        font-weight: $font-weight-semi-bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $color-dark-2;

        &.mat-focused {
          .mat-mdc-text-field-wrapper {
            border: 1px solid rgba(73, 89, 122, 0.2);
          }
        }

        .mat-mdc-form-field-infix {
          border: none !important;
        }

        .mat-form-field-underline,
        .mdc-line-ripple {
          display: none;
        }

        .mat-mdc-form-field-flex {
          padding-right: 10px;
        }

        mat-error {
          font-size: 12px;
          position: absolute;
          bottom: -18px;
          left: 2px;
        }

        &.phone-form-field {
          &.error {
            .mat-mdc-text-field-wrapper {
              // border: 1px solid $color-error;
            }
          }
        }
      }
    }

    .country-tel-form-field-container {
      .mdc-text-field {
        padding-left: 5px;
      }

      ::ng-deep {
        .mdc-text-field {
          padding-left: 5px;
        }
      }
    }

    .form-copy {
      margin-top: 20px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $color-dark-2;

      a {
        text-decoration: underline;
        color: $color-dark-2;
      }
    }

    .date-input {
      text-align: center;
      cursor: pointer;
    }

    .custom-error {
      position: absolute;
      left: 0px;
      bottom: -4px;
      font-size: 12px;
      color: $color-error;
      font-weight: $font-weight-semi-bold;
    }

    .btn-gender {
      padding: 12px 0;
      font-size: 16px;
      font-weight: $font-weight-semi-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      border-radius: 5px;
      color: $color-dark-2;
      border: solid 1px $color-light-4;
      background-color: $color-light-5;
      margin-bottom: 27px;

      .btn-icon {
        svg {
          margin-top: -3px;
          margin-right: 2px;
          width: 22px;
          height: 22px;

          circle,
          line,
          polyline {
            stroke: $color-dark-2;
          }
        }
      }

      &.active {
        color: $color-light-1;
        background: $appic-color-primary;

        .btn-icon svg {
          circle,
          line,
          polyline {
            stroke: $color-light-1;
          }
        }

        // &.male {
        //   border: solid 1px #4da9ff;
        //   background-color: #7ec1ff;
        // }

        // &.female {
        //   border: solid 1px #ff0586;
        //   background-color: #ff5cb1;
        // }
      }
    }

    .btn-container {
      margin-top: 120px;
    }

    .btn-submit {
      margin-bottom: 15px;
      padding: 12px 30px;
      font-size: 16px;
    }
  }

  .year-input-container {
    min-width: 110px;
  }
}
.text-color-primary {
  text-decoration: underline;
  color: $appic-color-primary;
  font-weight: 500;
}

.auth-panel-bottom {
  .auth-panel-bottom-info {
    text-align: center;
  }
}

.already-exists-container,
.validation-progress-container {
  text-align: center;
  position: relative;
  top: -5px;

  p {
    margin: 2px;
    font-size: 14px;
  }

  .text-bold {
    font-weight: 700;
    text-decoration: underline;
  }
}

.country-selector {
  position: static;
}

/* .code-input-container {
  padding-bottom: 70px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
} */

.code-info {
  margin-top: 50px;
  text-align: center;
  font-weight: 700;
}

.btn-primary.within-dialog {
  text-align: center;
  width: 100%;
  border-radius: 4px !important;
}

.header {
  text-align: center;
}

.password-requirements {
  ul {
    padding-left: 30px;
    font-size: 15px;
    margin-bottom: 90px;
  }

  .password-condition {
    .icon-check {
      display: none;
      position: relative;
      left: -8px;
      top: -1px;
    }

    &.active {
      list-style-type: none;
      color: $color-green;
      font-weight: 500;
      position: relative;
      left: -12px;

      .icon-check {
        display: inline;
      }
    }
  }
}

@media screen and (max-width: $max-width-sm) {
  .auth-panel {
    .gender-btn-container {
      padding: 0;

      .btn-gender {
        border-radius: 0px !important;
      }

      &:first-of-type {
        padding-left: 15px;

        .btn-gender {
          border-radius: 5px 0 0 5px !important;
        }
      }

      &:last-of-type {
        padding-right: 15px;

        .btn-gender {
          border-radius: 0 5px 5px 0 !important;
        }
      }
    }

    .date-input-container {
      padding: 0;

      .mat-mdc-text-field-wrapper {
        border-radius: 0px !important;
      }

      &:first-of-type {
        padding-left: 15px;

        .mat-mdc-text-field-wrapper {
          border-radius: 5px 0 0 5px !important;
        }
      }

      &:last-of-type {
        padding-right: 15px;

        .mat-mdc-text-field-wrapper {
          border-radius: 0 5px 5px 0 !important;
        }
      }
    }
  }
}
/* 
@media screen and (min-width: $width-sm) {
  .auth-panel {
    .date-input-container:not(:last-of-type) {
      &::after {
        position: absolute;
        content: '';
        top: 22px;
        right: -5.5px;
        height: 2px;
        width: 12px;
        background-color: $color-dark-2;
      }
    }
  }
} */

// Mobile
@media screen and (max-width: $max-width-md) {
  .auth-reset-page {
    .auth-panel {
      min-height: auto;
    }
  }

  .auth-panel {
    .auth-panel-header {
      padding: 50px 15px 15px;

      .header {
        font-size: 25px;
        text-align: center;
      }
    }

    .auth-panel-body {
      padding: 15px;
    }

    .auth-panel-bottom {
      padding: 0px 15px 50px 15px;
    }

    .btn-facebook {
      display: block;
      width: 100%;
      padding: 15px 0;
      border: none;

      @media screen and (max-width: 350px) {
        .btn-icon {
          display: none;
        }
      }
    }
  }
}
.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: white !important;
}

.fb-spinner {
  right: 12px;
  top: 50%;
  position: absolute !important;
  transform: translate(0%, -50%);
}
// Desktop
@media screen and (min-width: $width-md) {
  .auth-panel {
    .auth-panel-header {
      padding: 50px 15px 15px;

      .header {
        font-size: 40px;
      }
    }

    .auth-panel-body {
      padding: 15px;
    }

    .auth-panel-bottom {
      padding: 0px 15px 50px 15px;
    }
  }
}
