@import './src/styles/variables-styles.scss';

.auth-panel-body {
  padding-bottom: 70px !important;
}

.link_sent-info {
  margin: 40px 0 80px;

  p {
    text-align: center;
    margin-bottom: 5px;
  }
}

.resend-link-container {
  margin: 40px 0 15px;
  text-align: center;
}
.text-color-primary {
  text-decoration: underline;
  color: $appic-color-primary;
  font-weight: 500;
}

.cta-btn {
  cursor: pointer;
}
