.wide-btn {
  position: relative;
  svg-icon {
    position: absolute;
    left: 20px;
  }
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: white !important;
}
